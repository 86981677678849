import React, { useContext } from 'react';
import styled from 'styled-components';
import SearchContext from '../../context/SearchContext';

const StyledSearchField = styled.input`
  width: 100%;
  height: auto;
  max-width: 100px;
`;

const SearchField = () => {
  const search = useContext(SearchContext);

  const handleSearch = e => {
    search.updateSearchTerm(e.target.value);
  };

  return <StyledSearchField type="search" onChange={handleSearch} />;
};

export default SearchField;
