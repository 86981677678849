const slugify = require('slugify');

slugify.extend({
  ä: 'ae',
  ö: 'oe',
  ü: 'ue',
  ß: 'ss',
});

const options = { remove: /[*+~.()'"!:@/]/g, lower: true };
const customSlugify = inputString => slugify(inputString, options);

module.exports = customSlugify;
