import React, { useContext } from 'react';
import { Link } from 'gatsby';
import slugify from '../../utils/slugify';
import SearchContext from '../../context/SearchContext';

function SearchResults() {
  const { searchResults: results } = useContext(SearchContext);

  return (
    <div>
      {results.map(result => {
        const slug = slugify(result.node.childMarkdownRemark.frontmatter.title || result.node.id);
        return (
          <div key={`result_${result.node.id}`}>
            <Link to={`/${slug}`}>{result.node.childMarkdownRemark.frontmatter.title}</Link>
          </div>
        );
      })}
    </div>
  );
}

export default SearchResults;
