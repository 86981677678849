import axios from 'axios';
import React, { useState } from 'react';
import SearchField from '../components/SearchField';
import SearchResults from '../components/SearchResults';
import SearchContext from '../context/SearchContext';

const search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const getSearchResults = inputValue => {
    if (
      typeof window !== 'undefined' &&
      window.location.hostname === 'localhost' &&
      (window.location.port === '8000' || window.location.port === '9000')
    ) {
      console.warn('searching only works deployed online or with netlify dev');
    } else {
      axios.request({ url: `/.netlify/functions/search?q=${inputValue}` }).then(results => {
        setSearchResults(results.data.results);
      });
    }
  };

  const updateSearchTerm = inputValue => {
    setSearchTerm(inputValue);
    getSearchResults(inputValue);
  };
  return (
    <div>
      <SearchContext.Provider
        value={{
          searchTerm,
          updateSearchTerm,
          searchResults,
        }}
      >
        <SearchField />
        <SearchResults />
      </SearchContext.Provider>
    </div>
  );
};

export default search;
